function MenuChoice(props) {
    return(
      <div 
        id={`menu_${props.entry}`}
        onClick={() => {
          if(props.link) window.open(props.link, '_blank')
          else if(props.entry) props.setMenu(props.entry)
        }}
        className={`
          pointer
          relative h-full aspect-square rounded-xl drop-shadow-xl
          flex flex-col justify-center items-center 
          overflow-hidden hover:overflow-visible
          transition-all transform-gpu ease-out duration-100
          group
          ${ props.menu === props.entry ? 'animate-bounceMenu' : '' }
        `}
      >
        <p 
          className={`
            scale-[0.65]
            absolute top-[-2rem] md:top-[-1.65rem]
            h-fit w-fit rounded-xl text-nowrap
            md:py-1 md:px-2 bg-[#27303a] 
            text-center text-xs md:text-sm 
            font-semibold
            m-auto
            border-t-[1px] border-l-[1px] border-r-[1px] border-[#797A7D]/40
            outline outline-[1px] outline-black
            capitalize
          `}
        >
          {props.entry.replace('-', ' ')}
        </p>
        <img draggable={false} src={`./assets/menu/${props.entry}.png`} alt="menu-btn" className="rounded-xl w-full aspect-square pointer-events-none" />
        {
          props.link
            ? <img src="./assets/menu/shortcut.png" alt="shortcut" className="z-30 absolute left-1 bottom-1 w-[0.25rem] md:w-3" />
            : ''
        }
      </div>
    )
}

export function Menu(props) {
  return(
    <div className={`
      z-30 
      w-full md:w-fit h-8 md:h-16 
      mx-auto px-2
      absolute inset-x-0 bottom-2 
      m-auto 
      flex flex-row justify-evenly items-center gap-2 md:gap-4 
      rounded-xl
      shadow-xl font-['Andale']
      bg-[#3B4045]/40 text-[#AEB2B6] 
      md:backdrop-blur-xl
      border-t-[1px] border-l-[1px] border-r-[1px] border-[#797A7D]/40
      outline outline-[1px] outline-black
    `}>
      <MenuChoice entry={'home'} setMenu={props.setMenu} menu={props.menu} showMenu={true}/>
      <MenuChoice entry={'pfp'} setMenu={props.setMenu} menu={props.menu} showMenu={true}/>
      <MenuChoice entry={'achievements'} setMenu={props.setMenu} menu={props.menu} showMenu={true}/>
      <MenuChoice entry={'mail'} setMenu={props.setMenu} menu={props.menu} showMenu={true}/>
      <MenuChoice entry={'wallet'} setMenu={props.setMenu} menu={props.menu} showMenu={true}/>
      <MenuChoice entry={'stocks'} setMenu={props.setMenu} menu={props.menu} showMenu={true}/>
      <div className="w-[2px] h-4/6 py-2 rounded-lg bg-white opacity-[0.05]"></div>
      <MenuChoice entry={'binance'} link={'https://magiceden.io/runes/BITCOIN%E2%80%A2BRO%E2%80%A2BEAR'}/>
      <MenuChoice entry={'twitter'} link={'https://x.com/btcbrobear'}/>
      <MenuChoice entry={'telegram'} link={'https://t.me/btcbrobear'}/>
      <MenuChoice entry={'wechat'} setMenu={props.setMenu} menu={props.menu} showMenu={true}/>
      <div className="w-[2px] h-4/6 py-2 rounded-lg bg-white opacity-[0.05]"></div>
      <MenuChoice entry={'ai-generator'} link={'https://glif.app/@AnonPunkBTC/glifs/cm3c7y9sg0012so1bwww01cgi'} />
      <MenuChoice entry={'memes-depot'} link={'https://memedepot.com/d/bitcoin-bro-bear-bro'} />
  </div>
  )
}