import { Button } from "./Button";
import { Gummy } from "./Gummy";

export function XVerseButton(props) {
    return(
        <div
            className="z-40 pointer transition-all transform-gpu ease-in duration-100 z-10 absolute p-2 top-12 right-4 md:right-16 w-auto drop-shadow-md font-['Andale'] text-xs text-center text-white font-bold" 
        >
            <Button 
                text={'My Wallet'}
                handler={() => { props.setWalletState(!props.walletState)}}
            />
        </div>
    )
}
export function XVerse(props) {
    return(
        <div 
            className={`z-[99] pointer transition-all transform-gpu ease-in-out delay-100 duration-1000 absolute right-0 left-0 md:left-auto md:right-16 h-full md:h-3/6 z-20 ${props.walletState ? 'top-0' : 'top-[-100%]'}`}
        >
            <div className="h-full absolute bottom-0 bg-red-500 z-[100]">
                <Gummy id={6} size={'1.75rem'} left={10} />
            </div>
            <img 
                draggable={false}
                alt="xverse" 
                src="./assets/wallet.png" 
                className="h-full w-full shadow-xl" 
                onClick={() => { props.setWalletState(!props.walletState)}}
            />
        </div>
    )
}