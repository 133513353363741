import { motion } from "framer-motion"
import { Gummy } from "./Gummy";

export function Cosmonaut(props) {
    const y = Math.floor(Math.random() * (20 - 1 + 1) + 1);
    
    return(
        <div
            className="z-[21] m-auto absolute left-0 h-12 w-12 md:h-20 md:w-20 animate-spaceDrift group" 
        >
            <motion.div
                drag 
                className="w-full h-full" 
                style={{
                    top: `${y}%`,
                    touchAction: "none"
                }}
                >
                <img src="./assets/missionmoon.png" alt="we_failed_the_mission_bro" className={`z-[16] absolute top-[-10%] right-[-75%] scale-[0%] group-hover:scale-[200%] transition-all transform-gpu duration-300 delay-300 ease-out`} />
                <div className="z-[19] relative animate-rotate3d transition-all transform-gpu duration-100 ease-out">
                    <Gummy id={5} size={'2.75rem'} left={50} />
                </div>
                <img src="./assets/cosmonaut.png" alt="bro" className="z-[18] h-full w-full group-hover:drop-shadow-interactive animate-rotate3d transition-all transform-gpu duration-100 ease-out" draggable={false} />
                <div className="z-[19] relative animate-rotate3d transition-all transform-gpu duration-100 ease-out h-full left-0 bottom-0">
                    <Gummy id={8} size={'1.75rem'} left={0}/>
                </div>
            </motion.div>
        </div>
    )
  }
  
  