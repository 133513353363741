import { useEffect, useState } from "react"

export function Call(props) {
    const [state, setState] = useState(false)
    const [alreadyShown, setAlreadyShown] = useState(false)

    const handleClick = () => { setState(false) }

    useEffect(() => {
        if(window.innerWidth >= 768) return
        setTimeout(() => {
            if(alreadyShown) return
            setState(true)
            setAlreadyShown(true)
        }, 60000)
    }, [])

    useEffect(() => {
    }, [state])
    
    return(
        <div 
            className={`z-[99] pointer transition-all transform-gpu ease-in-out delay-100 duration-1000 absolute right-0 left-0 md:left-auto md:right-16 h-full md:h-3/6 z-20 ${state ? 'top-0' : 'top-[-100%]'}`}
        >
            <img 
                draggable={false}
                alt='call-img' 
                src='./assets/money_calling.png' 
                className="h-full w-full shadow-xl" 
                onClick={handleClick}
                />
        </div>
    )
}