import React, { useEffect } from 'react';
import soundSuccess from '../assets/sounds/success.mp4'
import eventBus from "./EventBus";

export class AchievementController extends React.Component{
  constructor()  {
    super()
    this.audio = new Audio(soundSuccess)
    this.audio.volume = 1
    this.state = { 
      achievement: null,
    }
    this.achievementsData = [
      {
        "id": "savage",
        "name": "Savage",
        "hint": "Prof Bear loves his bitcoin",
        "caption": "Tried to steal the Prof Bear's bitcoin",
        "rarity": 0
      },
      {
        "id": "vibing",
        "name": "Vibing",
        "hint": "That volume slider is sick",
        "caption": "Your portfolio is down, time to celebrate !",
        "rarity": 2
      },
      {
        "id": "simping",
        "name": "Simping",
        "hint": "The bear found a soulmate ?",
        "caption": "Spied on Erin's conversation",
        "rarity": 1
      },
      {
        "id": "seenthings",
        "name": "I've seen things",
        "hint": "This one is hidden behind layers",
        "caption": "The bear is clean for sure",
        "rarity": 3
      },
      {
        "id": "realbro",
        "name": "Real Bro",
        "hint": "Find your perfect bro",
        "caption": "Found your perfect bro",
        "rarity": 3
      },
      {
        "id": "gummyhunter",
        "name": "Gummy Hunter",
        "hint": "Find 8 gummy bears",
        "caption": "Found the hidden gummies",
        "rarity": 1
      },
      {
        "id": "bearillionaire",
        "name": "Bearillionaire",
        "hint": "BeariiClicker, nice huh ?",
        "caption": "Mined 1 BTC in BeariiClicker",
        "rarity": 2
      },
      {
        "id": "happycasey",
        "name": "Happy Casey",
        "hint": "Seppuku😭😭😭🗡️",
        "caption": "Reached 1b mc and saved Casey",
        "rarity": 3
      },
      {
        "id": "saikopath",
        "name": "Saikopath",
        "hint": "🐹🔪",
        "caption": "Learned violence from the best 🐹🔪",
        "rarity": 2
      },
      {
        "id": "billy",
        "name": "Billy",
        "hint": "To billions and above 📈",
        "caption": "The billy glasses stays on 🤓",
        "rarity": 2
      },
      {
        "id": "worldpeace",
        "name": "World Peace",
        "hint": "🐈",
        "caption": "GIZMO IMAGINARY FRIEND",
        "rarity": 2
      },
      {
        "id": "friendlypanda",
        "name": "Friendly Panda",
        "hint": "🐼",
        "caption": "🐼",
        "rarity": 2
      },
      {
        "id": "sparky",
        "name": "Charity Lover",
        "hint": "⚡",
        "caption": "⚡",
        "rarity": 2
      },
    ]
  }
  componentDidMount() {
    eventBus.on("unlock", (name) => this.unlock(name) );
    //localStorage.setItem('achievements', JSON.stringify([]))//xxx
  }
  componentWillUnmount() {
    eventBus.remove("unlock");
  }
  setVolume = (value) => {
    this.audio.volume = value / 100
  }
  getVolume = () => {
    return this.audio.volume * 100
  }
  playAudio = () => {
    this.audio.play()
  }
  unlock = (e) => {
    const achievement = this.achievementsData.filter((entry) => entry.id === e.id).pop()
    const unlocked = localStorage.getItem('achievements') ? JSON.parse(localStorage.getItem('achievements')) : [] 
    if(!unlocked.includes(achievement.name)) {
      unlocked.push(achievement.name)
      localStorage.setItem('achievements', JSON.stringify(unlocked))
      this.setState({ achievement: achievement })
      this.playAudio()
      setTimeout(async () => {
        this.setState({ achievement: null })
      }, 5000)
    }
  }
  getAchievements = () => {
    const achievements = []
    const unlocked = localStorage.getItem('achievements') ? JSON.parse(localStorage.getItem('achievements')) : [] 
    for(let achievementId = 0; achievementId<this.achievementsData.length;achievementId++) {
      const achievement = this.achievementsData[achievementId]
      const achievementStatus = unlocked.includes(achievement.name)
      achievements.push({...achievement, unlocked: achievementStatus})
    }
    return achievements
  }
  componentDidUpdate(prevProps) {}
  render() {
    if(!this.state.achievement) return
    return(
      <div 
        className={`
          absolute
          top-4 left-4 md:left-auto md:right-4
          z-[109]
          h-16 aspect-[5/1]
          flex justify-start items-center gap-2
          rounded-xl
          bg-[#1C1C1C] text-white
          drop-shadow-xl
          translate-y-[-5rem]
          transition-all transform-gpu ease-in-out duration-300
          animate-popIn
        `}
      >
        <img alt="achievement-img" src={`./assets/achievements/${this.state.achievement.id}.png`} className="h-full aspect-square p-3" />
        <div className='flex flex-col justify-center items-start h-full w-full'>
          <div className='flex justify-start items-center gap-2 h-3/6'>
            <img alt="achievement-img" src={`./assets/trophies/${String(this.state.achievement.rarity)}.png`} className="h-3/6 aspect-square m-auto" />
            <p className='font-bold text-base'>{this.state.achievement.name}</p>
          </div>
          <p className='text-xs'>{this.state?.achievement.caption}</p>
        </div>
      </div>
    )    
  }
}

//export const Achievements = new AchievementController();