import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import eventBus from "./EventBus";
import soundGummy from '../assets/sounds/boop.mp3'

export function Gummy(props) {
    const [clicked, setClicked] = useState(false)
    const [collected, setCollected] = useState(false)

    const handleClic = () => {
        setClicked(true)
        const alreadyCollected = JSON.parse(localStorage.getItem('gummies')) ?? []
        const audio = new Audio(soundGummy)
        audio.volume = 0.5
        audio.play()
        if(!alreadyCollected.includes(props.id)) {
            alreadyCollected.push(props.id)
            localStorage.setItem('gummies', JSON.stringify(alreadyCollected))
            if(alreadyCollected.length === 8) eventBus.dispatch("unlock", { id: "gummyhunter" });
        }
    }

    useEffect(() => {
    }, [collected])

    useEffect(() => {
        const alreadyCollected = JSON.parse(localStorage.getItem('gummies')) ?? []
        setCollected(alreadyCollected.includes(props.id))
    }, [])

    const container = {
        expanded: {
            bottom: `0`,
            transition: {
                type: `spring`,
                mass: 7.5,
                damping: 60,
                stiffness: 420,
                velocity: 2,
            }
        }
    };
    if(collected) return;
    return(
        <div
            className="h-full absolute"
            style={{
                zIndex: props.zIndex,
                left: props.left ?? 'unset',
                right: props.right ?? 'unset',
            }}
        >
            <motion.div
                animate="expanded" variants={container}
                className="absolute h-fit cursor-pointer"
                style={{ width: props.size ?? '2.5rem' }}
                onClick={handleClic}
                onTouchStart={handleClic}
            >
                <p 
                    className={`text-lg text-center font-mono text-white/80 ${ clicked ? 'animate-gummyCollected' : 'opacity-0' }`} 
                    >
                    +1
                </p>
                <img
                    draggable={false}
                    alt='x' 
                    src={`./assets/gummies/${props.id}.png`} 
                    className={`m-auto w-full aspect-square hover:drop-shadow-interactive ${ clicked ? 'animate-gummyOut' : '' }`} 
                />
            </motion.div>
        </div>
    )
}