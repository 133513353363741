import { useEffect, useState } from 'react';
import axios from 'axios';
import { Popup } from './components/Popup.js';
import { Ship } from './components/Ship.js';
import { Cosmonaut } from './components/Cosmonaut.js';
import { DeskBro } from './components/DeskBro.js';
import { Borders } from './components/Borders.js';
import { CursorBear } from './components/CursorBear.js';
import { randomElement } from "./components/Utils.js";
import { XVerse, XVerseButton } from "./components/XVersePopup.js"
import { Menu } from './components/Menu.js';
import { Content } from './components/Content.js';
import { VolumeSlider } from './components/VolumeSlider.js';
import { AchievementController } from './components/AchievementController.js';
import { THRKHDE } from './components/THRKHDE.js';
import { Gummy } from './components/Gummy.js';
import { Call } from './components/Call.js';


function App() {
  const [btcUsd, setBtcUsd] = useState(0)
  const [menu, setMenu] = useState('home')
  const [walletState, setWalletState] = useState(false)
  const [gifs, setGifs] = useState(['1', '2'])
  const gifset = ['0', '1', '2', '3', '4', '5', '6', '7', '8']
  const [deskbro, setDeskbro] = useState(null)
  const [ship, setShip] = useState(null)

  useEffect(() => {
    const _gifs = gifset.sort(() => 0.5 - Math.random()).slice(0, 5);
    setGifs(_gifs)
    setDeskbro(randomElement(['0','1','2','3','4','5','6','7','8','9','10']));
    setShip(randomElement(['0','1']));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    try{
        axios
        .get("https://swissborg.com/api/v1/assets-live-feed")
        .then((data) => {
          setBtcUsd(data.data.crypto.BTC.price);
        })
        .catch(() => {
          console.log(" xx No available API to update BTC price");
        });
    } catch(e) { setBtcUsd(0) }
  }, []);

  useEffect(() => {}, [walletState, menu])

  /*
        <Popup title={""} link={"lewd"} size={14} top={`25%`} left={`5%`} gif={gifs[4]}/>
      <Popup title={"Bearish"} link={"kpop"} size={14} top={`23.5%`} right={`5%`} gif={gifs[2]}/>
  */
  return (
    <div id="app" className="z-10 select-none overflow-hidden bg-slate-600 flex justify-center items-center">
      <AchievementController />
      <VolumeSlider />
      <Content menu={menu} setMenu={setMenu} btcUsd={btcUsd} />
      <img draggable={false} src="./assets/bg.png" alt="bg" className={`z-[5] absolute m-auto left-0 top-0 right-0 bottom-0 h-[inherit] w-[inherit] brightness-0 opacity-1 animate-opacityOut`}/>
      <img draggable={false} src="./assets/bg.png" alt="bg" className="pointer-events-none absolute m-auto left-0 top-0 right-0 bottom-0 h-[inherit] w-[inherit] z-[2]"/>
      <CursorBear />
      <Cosmonaut />
      <Ship ship={ship} />
      <p className='z-20 text-white text-[0.4rem] md:text-xs absolute top-0 left-0 p-1 md:p-2'>Unexpected Server Error: <code>You're not bearish enough</code></p>
      <Popup title={"$bro"} link={"main"} size={10} top={`7.5%`} left={`1%`} gif={gifs[0]} />
      <Popup title={"ALERT BANGER"} link={"bros"} size={14} top={`10.5%`} left={`7.77%`} gif={gifs[1]}/>
      <Popup title={""} link={"whatstarteditall"} size={14} top={`auto`} bottom={`auto`} right={`0.5%`} gif={gifs[3]}/>
      <XVerseButton setWalletState={setWalletState} walletState={walletState}/>
      <XVerse setWalletState={setWalletState} walletState={walletState}/>
      <DeskBro deskbro={deskbro}/>
      <Menu setMenu={setMenu} menu={menu} />
      <THRKHDE />
      <Gummy id={1} left={0} zIndex={10}/>
      <Call />
    </div>
  );
}

export default App;
