import { useEffect, useState } from "react";
import eventBus from "../components/EventBus";
import { Gummy } from "../components/Gummy";

const mails = [
    [
        {
            "from": "Peter Todd (peterktodd@gmail.com)",
            "to": "Bitcoin Bro Bear (im_bearish_bro@btcbrobear.xyz)",
            "pfp": "./assets/satoshi.png",
            "subject": "We have a problem",
            "date": "15/10/2024",
            "content": "Bro, how long am I going to have to cover you on that shit?? \nThey're starting to think it's me. \n\nGive me a raise!",
            "contentImg": null,
            "originalMail": null
        },
        {
            "from": "Erin (erin@hell.money)",
            "to": "Bitcoin Bro Bear (im_bearish_bro@btcbrobear.xyz)",
            "pfp": "./assets/mailMenu/erin.jpg",
            "subject": "Re: Re: <3",
            "date": "10/10/2024",
            "content": "Plz stop I'm not bearish enough",
            "contentImg": null,
            "originalMail": {
                "from": "Bitcoin Bro Bear (im_bearish_bro@btcbrobear.xyz)",
                "to": "Erin (erin@hell.money)",
                "pfp": "./assets/mailMenu/bro.png",
                "subject": "Re: <3 ",
                "date": "09/10/2024",
                "content": ":(",
                "contentImg": "./assets/mailMenu/snap.png",
                "originalMail": {
                    "from": "Bitcoin Bro Bear (im_bearish_bro@btcbrobear.xyz)",
                    "to": "Erin (erin@hell.money)",
                    "pfp": "./assets/mailMenu/bro.png",
                    "subject": "<3 ",
                    "date": "07/10/2024",
                    "content": "Hi Erin, I'm your bro, the bear. This could be us:",
                    "contentImg": "./assets/mailMenu/love.jpg",
                    "originalMail": null
                }
            }
        },
    ],
    [
        {
            "from": "Bitcoin Bro Bear (im_bearish_bro@btcbrobear.xyz)",
            "to": "Erin (erin@hell.money)",
            "pfp": "./assets/mailMenu/bro.png",
            "subject": "Re: <3 ",
            "date": "09/10/2024",
            "content": ":(",
            "contentImg": "./assets/mailMenu/snap.png",
            "originalMail": null
        },
        {
            "from": "Bitcoin Bro Bear (im_bearish_bro@btcbrobear.xyz)",
            "to": "Erin (erin@hell.money)",
            "pfp": "./assets/mailMenu/bro.png",
            "subject": "<3> ",
            "date": "07/10/2024",
            "content": "Hi Erin, I'm your bro, the bear. This could be us:",
            "contentImg": "./assets/mailMenu/love.jpg",
            "originalMail": null
        }
    ],
    [
        {
            "from": "il Capo Of Crypto (CryptoCapo_@gmail.com)",
            "to": "Bitcoin Bro Bear (im_bearish_bro@btcbrobear.xyz)",
            "pfp": "./assets/capo.jpg",
            "subject": "Collab Request",
            "date": "02/10/2024",
            "content": "Gm, Mr. Bro. Are things getting bearish or is it just me ?",
            "contentImg": null,
            "originalMail": null
        },
    ],
]

export function MailView(props) {
    useEffect(() => {}, [props.mail])
    if(!props.mail) return
    return(
        <div 
            className="px-8 py-4 bg-[#2D2D2D] w-full flex justify-between items-stretch w-64 border-t-[22px] border-[#424242] outline outline-[1px] outline-black transition-all duration-100 ease-out" 
        >
            <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                    <p className="text-xs">{props.mail.subject}</p>
                    <p className="font-bold text-xs">From: {props.mail.from}</p>
                    <p className="font-bold text-xs">To: {props.mail.to}</p>
                </div>
                <div className="flex flex-col justify-start pt-4">
                    <p className="text-sm text-white/60">
                        {props.mail.content}
                    </p>
                    {
                        props.mail.contentImg
                            ? <img alt="mail-img" src={props.mail.contentImg} className="w-3/6"/>
                            : ''
                    }
                </div>
            </div>
            <div className="flex flex-col items-end gap-2 h-fit">
                <p className="text-xs">{props.mail.date}</p>
                <img alt="mail-pfp" src={props.mail.pfp} className="rounded-full shadow-inner w-12 aspect-square bg-white/10"/>
            </div>
        </div>
    )
}

export function MailPreview(props) {
    useEffect(() => {}, [props.selectedMail])
    return(
        <div 
            className="px-2 py-2 flex justify-between items-stretch w-64 pointer border-b-[2px] border-black transition-all duration-100 ease-out" 
            onClick={() => { props.handleSelectedMail(props.index) }}
            style={{ backgroundColor: props.selectedMail === props.index ? '#2860D3' : '' }}
        >
            <div className="flex flex-col gap-2">
                <div className="flex flex-col">
                    <p className="font-bold text-xs">{props.mail.from.split('(')[0]}</p>
                    <p className="text-xs">{props.mail.subject}</p>
                </div>
                <p className="text-xs text-white/60 w-8 text-nowrap">{props.mail.content.slice(0, 12)}{props.mail.content.length > 12 ? '...' : ''}</p>
            </div>
            <div className="flex flex-col">
                <p className="text-xs">{props.mail.date}</p>
            </div>
        </div>
    )
}

export function Mail(props) {
    const [selectedBox, setSelectedBox] = useState(0);
    const [selectedMail, setSelectedMail] = useState();

    const handleSelectedBox = (e) => {
        setSelectedMail(null)
        setSelectedBox(e)
    }
    const handleSelectedMail = (e) => {
        setSelectedMail(e)
        if(mails[selectedBox][e].from.includes('Erin')) eventBus.dispatch("unlock", { id: "simping" });
    }
    useEffect(() => {}, [selectedBox, selectedMail])
    return(
        <div className="z-30 h-full w-[inherit] flex flex-col md:flex-row justify-start items-stretch">
            <div className="bg-[#1E1E1E] flex justify-start">
                <div className="flex flex-col justify-start items-center pr-1 md:pr-4 border-b-[2px] md:border-b-[0px] border-r-[2px] border-black">
                    <p className="w-full text-md px-2 pt-1 md:px-4 md:pt-2">Mailboxes</p>
                    <div className="w-full flex flex-col justify-start gap-1 items-start px-4 py-1 md:px-8 md:py-2">
                        <div 
                            onClick={() => {handleSelectedBox(0)}} 
                            className={`
                                flex justify-start items-center gap-2 
                                pointer 
                                w-full px-2 md:px-4 py-1 
                                rounded-lg 
                                transition-all transform-gpu duration-100 ease-out
                                hover:bg-white/10 active:bg-white/20 ${selectedBox === 0 ? 'bg-white/20' : ''}
                            `}
                        >
                            <img alt="mail-img" src="./assets/mailMenu/email.png" className="w-3 aspect-square invert" />
                            <p className="text-xs text-white/60">Inbox</p>
                        </div>
                        <div 
                            onClick={() => {handleSelectedBox(1)}} 
                            className={`
                                flex justify-start items-center gap-2 
                                pointer 
                                w-full px-2 md:px-4 py-1 
                                rounded-lg 
                                transition-all transform-gpu duration-100 ease-out
                                hover:bg-white/10 active:bg-white/20 ${selectedBox === 1 ? 'bg-white/20' : ''}
                            `}
                        >
                            <img alt="mail-img" src="./assets/mailMenu/sent.png" className="w-3 aspect-square invert" />
                            <p className="text-xs text-white/60">Sent</p>
                        </div>
                        <div 
                            onClick={() => {handleSelectedBox(2)}} 
                            className={`
                                flex justify-start items-center gap-2 
                                pointer 
                                w-full px-2 md:px-4 py-1 
                                rounded-lg 
                                transition-all transform-gpu duration-100 ease-out
                                hover:bg-white/10 active:bg-white/20 ${selectedBox === 2 ? 'bg-white/20' : ''}
                            `}
                        >
                            <img alt="mail-img" src="./assets/mailMenu/trash.png" className="w-3 aspect-square invert" />
                            <p className="text-xs text-white/60">Trash</p>
                        </div>
                    </div>
                </div>
                <div className="bg-[#1E1E1E] w-fit md:w-64 flex flex-col justify-start items-center border-r-[2px] border-black">
                    {
                        mails[selectedBox]?.map((mail, index) => {
                            return <MailPreview key={index} index={index} mail={mail} selectedMail={selectedMail} handleSelectedMail={handleSelectedMail} />
                        })
                    }
                </div>
            </div>
            <div className="md:w-[45rem] h-[20rem] md:h-[40rem] bg-[#282828] flex flex-col justify-start items-center p-2 gap-4 overflow-auto">
                <MailView mail={mails[selectedBox][selectedMail]} />
                {
                    mails[selectedBox][selectedMail]?.originalMail
                    ? (
                        <div className="flex flex-col">
                            <p className="text-[0.55rem] text-white/60">Replying to:</p>
                            <MailView mail={mails[selectedBox][selectedMail]?.originalMail} />
                        </div>
                    )
                    : ''
                }
                {
                    mails[selectedBox][selectedMail]?.originalMail?.originalMail
                    ? (
                        <div className="flex flex-col relative">
                            <p className="text-[0.55rem] text-white/60">Replying to:</p>
                            <MailView mail={mails[selectedBox][selectedMail]?.originalMail?.originalMail} />
                            <Gummy id={3} size={'2.75rem'} left={90} />
                        </div>
                    )
                    : ''
                }
            </div>
        </div>
    )
}