import { motion } from "framer-motion"
import eventBus from "../components/EventBus";
import { Sparkle } from "../components/Sparkle"

export function Home(props) {
    const handlerAchievement = () => {
        eventBus.dispatch("unlock", { id: "savage" });
    }
    return(
        <div className="z-30 h-full flex flex-col md:flex-row justify-center">
            <div className="w-full p-2 md:p-4 flex flex-col justify-center items-center gap-2 md:gap-4">
                <h1 className="w-fit font-bold text-sm md:text-lg text-center">Welcome to the BITCOIN•BRO•BEAR website</h1>
                <p></p>
                <div className="flex flex-col justify-evenly items-center">
                    <p className="w-fit text-xs md:text-sm text-center">The most bearish rune on the market</p>
                    <p className="italic text-xs">Released as a fair mint with no premine</p>
                </div>
                <p></p>
                <p className="italic text-xs">Nothing here is financial advice</p>
            </div>
            <div className="z-30 w-32 m-auto md:w-64 h-full relative" onTouchStart={handlerAchievement} onMouseDown={handlerAchievement}>
                <motion.div drag dragSnapToOrigin={true} className="group absolute z-[31]">
                    <Sparkle speed={2} delay={0} x={7.5} y={7.5} bounce={1}/>
                    <Sparkle speed={3} delay={1} x={25} y={17.5} bounce={1}/>
                    <img draggable={false} alt="prof-coin" src="./assets/home/coin.png" className="z-30 w-full h-full group-hover:drop-shadow-interactive animate-bounceLow"/>
                </motion.div>
                <img draggable={false} alt="prof-bear" src="./assets/home/prof.png" className="w-full h-full"/>
            </div>
        </div>
    )
}
